<template>
  <div class="page page__faq-article">
    <esmp-loader v-show="loading" fix />

    <article-item
      v-if="article.content"
      :title="article.title"
      :content="article.content"
      :attachments="article.attachments"
    >
      <template v-if="false" v-slot:side>
        <additional-documents
          class="page__faq-article__docs"
          title="Читайте также"
          :items="additionalArticles"
        />
      </template>
    </article-item>
  </div>
</template>

<script>
import ArticleItem from '@/components/article-item';
import AdditionalDocuments from '@/components/additional-documents';
import { faqGetParentsMixin } from '@/components/faq';
import breadcrumb from '@/utils/breadcrumb';
import getFileFormat from '@/helpers/getFileFormat';
import getFileExtension from '@/helpers/getFileExtension';

export default {
  name: 'FaqArticle',

  components: { AdditionalDocuments, ArticleItem },

  mixins: [faqGetParentsMixin],

  props: {
    articleId: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      article: {},
      loading: false,
      additionalArticles: Object.freeze([
        {
          title: 'Название статьи в 1 строчку',
          url: '/faq/article/1',
        },
        {
          title: 'Длинное название статьи в 2 строчки',
          url: '/faq/article/1',
        },
        {
          title: 'Название статьи в 1 строчку',
          url: '/faq/article/1',
        },
        {
          title: 'Длинное название статьи в 2 строчки',
          url: '/faq/article/1',
        },
      ]),
    };
  },

  created() {
    this.getFaqArticle();
  },

  methods: {
    async getFaqArticle() {
      this.loading = true;

      const {
        id,
        solution,
        task,
        title,
        attachments,
        category: { id: parentCategoryId },
      } = await this.$API.faq.getArticle(this.articleId);

      const currentDescription = `<div class="article-item__content-item"><h3>Описание</h3>${task}</div>`;
      const currentSolution = solution
        ? `<div class="article-item__content-item"><h3>Решение</h3><p>${solution}</p></div>` : '';
      this.article.content = `${currentDescription}${currentSolution}`;
      this.article.title = title;
      this.article.attachments = attachments.map((file) => ({
        url: `/api/faq/articles/${this.articleId}/attachments/${file.id}`,
        id: file.id,
        name: file.fileName,
        size: file.fileSize,
        format: getFileFormat(file.fileName),
        extension: getFileExtension(file.fileName),
        downloadParams: {
          articleId: this.articleId,
          attachmentId: file.id,
        },
      }));

      await this.setBreadcrumbsFromParents(parentCategoryId, [
        breadcrumb(title, `/faq/article/${id}`),
      ]);

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@include for-size(phone-portrait-down) {
  .page__faq-article__docs {
    margin-left: 0;
  }
}
</style>
